import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import General from "../utils/General";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import Overview from "./components/company/Overview";
import Transactions from "./components/tables/Transactions";
import FilingsTable from "./components/tables/Filings";
import Stores from "./components/company/Stores";
import Profile from "./components/company/Profile";
import CompanyMembers from "./components/tables/CompanyMembers";
import Messages from "./components/messages/Messages";
import Permissions from "../utils/Permissions";
import Payments from "./components/company/Payments";
import TaxDetails from "./components/tables/TaxNumbers";
import Reports from "./components/tables/Reports";
import Subscription from "./components/company/Subscription";

export default class Company extends React.Component {

  constructor(props) {
    super(props)

    let tabs = [
      {
        title: "Overview",
        permission: true
      },
      {
        title: "Filings",
        permission: Permissions.hasViewPermission(Permissions.FILINGS)
      },
      {
        title: "Transactions",
        permission: Permissions.hasViewPermission(Permissions.TRANSACTIONS)
      },
      {
        title: "Messages",
        permission: Permissions.hasViewPermission(Permissions.MESSAGES)
      },
      {
        title: "Stores",
        permission: Permissions.hasViewPermission(Permissions.INTEGRATIONS)
      },
      {
        title: "Payments",
        permission: Permissions.hasViewPermission(Permissions.PAYMENTS)
      },
      {
        title: "Tax Numbers",
        permission: Permissions.hasViewPermission(Permissions.TAXES)
      },
      {
        title: "Reports",
        permission: Permissions.hasViewPermission(Permissions.REPORTS)
      },
      {
        title: "Profile",
        permission: true
      },
      {
        title: "Team",
        permission: AuthManager.currentUser.user.role === "admin"
      },
      {
        title: "Subscription",
        permission: AuthManager.currentUser.user.role === "admin"
      },
    ]

    this.state = {
      selectedTab: window.location.hash ? window.location.hash.substring(1) : 'overview',
      currentUser: AuthManager.currentUser,
      tabs
    }
  }

  componentDidMount(){
    this._load()
  }

  _load(){
    let { companyId } = this.props.match.params
    Backend.getCompany(companyId)
      .then(company => {
        this.setState({ company })
      })
  }

  _renderTabs(){
    let {
      tabs,
      selectedTab
    } = this.state

    return (
      <>
        {
          tabs.map(tab => {
            if(tab.permission){
              return (
                <li className="nav-item">
                  <a
                    className={`nav-link text-active-primary me-6 ${selectedTab === tab.title.toLowerCase() ? 'active' : ''}`}
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        selectedTab: tab.title.toLowerCase()
                      })
                    }}
                  >
                    {tab.title}
                  </a>
                </li>
              )
            }
          })
        }
      </>
    )
  }

  _renderTabContent(){
    let{
      company,
      selectedTab
    } = this.state

    switch (selectedTab) {
      case "overview":
        return (
          <>
            <Overview company={company} />
            {Permissions.hasViewPermission(Permissions.TRANSACTIONS) && (
              <Transactions title={"Transactions"} companyId={company.id} />
            )}
          </>
        );

      case "filings":
        return <FilingsTable companyId={company.id} />;

      case "transactions":
        return <Transactions title={"Transactions"} companyId={company.id} />;

      case "messages":
        return <Messages companyId={company.id} />;

      case "stores":
        return <Stores companyId={company.id} />;

      case "payments":
        return (
          <Payments
            companyId={company.id}
            bankAccountConnected={company.bank_account_connected}
          />
        );

      case "tax numbers":
        return <TaxDetails company={company} />;

      case "reports":
        return <Reports company={company} />;

      case "profile":
        return (
          <Profile
            company={company}
            onSuccess={updatedCompany => this.setState({ company: updatedCompany })}
          />
        );

      case "team":
        return <CompanyMembers companyId={company.id} />;

      case "subscription":
        return (
          <Subscription
            company={company}
            onSuccess={updatedCompany => this.setState({ company: updatedCompany })}
          />
        );

      default:
        return null; // Optional: Handle a fallback for unknown tabs
    }
  }

  render() {
    let {
      company,
      selectedTab
    } = this.state

    if(!company) return null

    let onboardingStatus = company.info.status
    let className = 'badge-light-success'
    let status = "Complete"

    if(onboardingStatus === "detail"){
      className = 'badge-light-warning'
      status = "Tax Number"
    }

    if(onboardingStatus === "tax"){
      if(!company.info.tax_no){
        className = 'badge-light-dark'
        status = "Tax Info"
      }
      else{
        className = 'badge-light-primary'
        status = "EORI Number"
      }
    }

    if(onboardingStatus === "integration"){
      className = "badge-light-danger"
      status = "Shopify"
    }

    return (
      <>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                        {company.name}
                      </a>
                      <span className={`badge ${className} badge-sm fs-base ms-2`}>
                        {status}
                      </span>
                    </div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                        <span className="svg-icon svg-icon-4 me-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                               fill="none">
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            />
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        {company.created_by.user.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex overflow-auto h-55px">
              <ul
                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
              >
                {this._renderTabs()}
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane fade show active`} role="tabpanel">
            {
              this._renderTabContent()
            }
          </div>
        </div>
      </>
    )
  }

}
