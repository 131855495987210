import React from 'react'

export default class Card extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      integration: props.integration
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render(){
    let {
      integration
    } = this.state

    if(!integration) return null

    let connectedTitle = "Disconnected"
    if(integration.status === "active"){
      connectedTitle = "Connected"
    }else if(integration.status === "revoked"){
      connectedTitle = "Revoked"
    }else if(integration.status === "pending"){
      connectedTitle = "Pending"
    }

    let title = 'Shopify'
    let backgroundColor = 'bg-primary'
    let textColor = 'text-white'
    let buttonColor = 'btn-outline-white'
    let logo = {
      backgroundPosition: '100% 50px',
      backgroundSize: '200px auto',
      backgroundImage:`url(${require('../../../../assets/media/logos/shopify.svg').default})`
    }

    if(integration?.type === "woocommerce"){
      title = 'WooCommerce'
      backgroundColor = 'bg-woo-commerce'
      logo = {
        backgroundPosition: '100% 90px',
        backgroundSize: '500px auto',
        backgroundImage:`url(${require('../../../../assets/media/logos/woocommerce-inverse.svg').default})`
      }
    }

    if(integration?.type === "amazon_file"){
      title = ''
      backgroundColor = 'bg-primary'
      logo = {
        backgroundPosition: '95% 40px',
        backgroundSize: '200px auto',
        backgroundImage:`url(${require('../../../../assets/media/logos/file-upload.svg').default})`
      }
    }

    if(integration?.type === "shopline"){
      title = 'Shopline'
      backgroundColor = 'bg-shopline'
      logo = {
        backgroundPosition: '97% 200px',
        backgroundSize: '290px auto',
        backgroundImage:`url(${require('../../../../assets/media/logos/shopline.svg').default})`
      }
    }

    return (
      <div
        className={`card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px ${backgroundColor} mb-5 mb-xl-8`}
        style={logo}
      >
        <div className="card-body d-flex flex-column justify-content-center">
          <h3 className={`${textColor} fs-2x fw-bolder line-height-lg mb-5`}>
            {title} Account
            <br/>{connectedTitle}
          </h3>

          {
            integration.status === "active" &&
            <>
              <button
                className={`btn btn-sm ${buttonColor} w-100px`}
                onClick={event => {
                  if(integration.type === "shopify"){
                    window.open(`https://${integration.data.shop}`, '_blank')
                  }else{
                    window.open(`${integration.data.shop}`, '_blank')
                  }
                }}
              >
                View
              </button>
            </>
          }

        </div>
      </div>
    )
  }
}
