import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";


class LineItems extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      record: props.record
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let { record } = this.state
    let columns = [
      {
        Header: 'Item',
        id: 'description',
        width: 200,
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          return (
            <div class="d-flex align-items-center">
							<span class="text-dark text-hover-primary fs-7">
                {`${lineItem.quantity}x ${lineItem.description}`}
              </span>
						</div>
          )
        }
      },
      {
        Header: 'Unit Subtotal',
        id: 'unit_subtotal',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          let multiplier = (record.subtype === "credit_note" || record.subtype === "refund") ? -1 : 1
          let unitSubtotal = lineItem.unit_subtotal
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, unitSubtotal, multiplier)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Unit Tax',
        id: 'tax',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          let multiplier = (record.subtype === "credit_note" || record.subtype === "refund") ? -1 : 1
          let unitTax = lineItem.unit_tax
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, unitTax, multiplier)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Unit Total',
        id: 'unit_total',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          let multiplier = (record.subtype === "credit_note" || record.subtype === "refund") ? -1 : 1
          let unitTotal = lineItem.unit_total
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, unitTotal, multiplier)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{lineItem.quantity}</a>
            </div>
          )
        }
      },
      {
        Header: 'Tax',
        id: 'tax',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          let multiplier = (record.subtype === "credit_note" || record.subtype === "refund") ? -1 : 1
          let tax = lineItem.tax
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, tax, multiplier)}</a>
            </div>
          )
        },
      },
      {
        Header: 'Total',
        id: 'total',
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let symbol = record.currency.symbol
          let multiplier = (record.subtype === "credit_note" || record.subtype === "refund") ? -1 : 1
          let total = lineItem.total
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, total, multiplier)}</a>
            </div>
          )
        }
      },
      {
        Header: 'Tax Rates',
        sortable: false,
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let taxRates = lineItem.taxes.map(tax => parseFloat(tax.tax_rate.rate) * 100)
          taxRates = taxRates.map(rate => `${rate.toFixed(2).replace(/\.?0+$/, '')}%`)
          let taxRateString = taxRates.join(', ') || '0%'
          return (
            <div class="d-flex align-items-center">
              <a class="text-dark text-hover-primary fs-6">{taxRateString}</a>
            </div>
          )
        }
      },
      {
        Header: 'Shipping',
        sortable: false,
        Cell: rowInfo => {
          let lineItem = rowInfo.original
          let origin = `${lineItem.origin_location?.country_code || ''}`
          let destination = record.shipping_address?.country_code || record.billing_address?.country_code || ""
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                {
                  destination &&
                  <>
                    {origin && <span className=" fw-bold fs-7">{`From: ${origin}`}</span>}
                    <span className=" fw-bold d-block fs-7">{`To: ${destination}`}</span>
                  </>
                }
              </div>
            </div>
          )
        }
      },
    ]

    return columns
  }


  render() {
    let {
      record
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.LineItems}?record_id=${record.id}`}
          noDataMessage={"No line items found"}
          title={this.props.title}
          columns={columns}
          objects={'active'}
          showSearch={false}
          showFilter={false}
          dateRange={false}
          defaultSorted={[
            {
              id: "total",
              desc: true
            }
          ]}
        />
      </>
    )

  }

}

LineItems.defaultProps = {
  title: "Line Items"
}

export default withRouter(LineItems);
